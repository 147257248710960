<template>
    <div class="mainBox">
        <!--Header-->
        <div class="handleBox flex flex-ai-c">
            <el-page-header @back="goBack" content="会员管理 — 基本信息"></el-page-header>
        </div>

        <div class="memberDet">
            <el-row>
                <el-col :span="12">
                    <div style="margin-right:5px">
                        <el-card shadow="never">
                            <el-row type="flex" align="center" :gutter="20" class="top_member">
                                <el-col class="flex flex-ai-c flex-jc-c" :span="4">
                                    <img v-if="!imgStatus" :src="formData.headimgurl" />
                                    <el-avatar v-if="imgStatus" shape="circle" :src="require('../../../assets/ava.png')"></el-avatar>
                                </el-col>
                                <el-col :span="12">
                                    <div class="ellipisis">姓名：{{formData.realname?formData.realname: '暂无'}}</div> 
                                    <div class="ellipisis">电话：{{formData.mobile?formData.mobile:'暂无' }} </div> 
                                    <div class="ellipisis">昵称：{{formData.nickname?formData.nickname:'暂无'}}</div> 
                                    <div class="ellipisis">上级昵称：{{formData.parent_nickname?formData.parent_nickname:'暂无'}}</div> 
                                </el-col>
                                <el-col :span="8">
                                    <div class="ellipisis">账户余额：{{formData.balance}}</div> 
                                    <div class="ellipisis">会员等级：{{formData.level_name?formData.level_name : '暂无'}}</div> 
                                    <div class="ellipisis">会员卡到期：{{formData.level_id_deadline ? formData.level_id_deadline : '暂无'}}</div> 
                                    <div class="ellipisis">注册时间：{{formData.create_time}}</div> 
                                </el-col>
                            </el-row>
                        </el-card>
                    </div>
                </el-col>
                <el-col :span="12">
                    <el-card shadow="never">
                        <el-row type="flex" :gutter="20" class="top_member">
                            <el-col :span="8">
                                <label :class="[activeName == 'recharge' ? 'order_col_label' : '']">充值总次数：{{formData.recharge_count}}次 </label><br>
                                <label :class="[activeName == 'recharge' ? 'order_col_label' : '']">充值总金额：{{formData.recharge_sum}}元 </label><br>
                                <label :class="[activeName == 'share' ? 'order_col_label' : '']">推广总次数：{{formData.son_count}}次 </label><br>
                                <label :class="[activeName == 'share' ? 'order_col_label' : '']">推广总金额：{{formData.share_balance}}元 </label><br>
                            </el-col>
                            <el-col :span="8">
                                <label :class="[activeName == 'integral' ? 'order_col_label' : '']">会员总积分：{{formData.integral_sum}} </label><br>
                                <label :class="[activeName == 'integral' ? 'order_col_label' : '']">剩余总积分：{{formData.integral}} </label><br>
                                <label :class="[activeName == 'discount' ? 'order_col_label' : '']">领取优惠券：{{formData.discount_count}}个 </label><br>
                                <label :class="[activeName == 'discount' ? 'order_col_label' : '']">剩余优惠券：{{formData.discount_valid_count}}个 </label><br>
                            </el-col>
                            <el-col :span="8">
                                <label :class="[activeName == 'order' ? 'order_col_label' : '']">订单总个数：{{formData.order_count}}个 </label><br>
                                <label :class="[activeName == 'order' ? 'order_col_label' : '']">订单总金额：{{formData.order_amount_payable_sum}}元</label> <br>
                                <label :class="[activeName == 'order' ? 'order_col_label' : '']">退款订单数：{{formData.order_back_count}}个 </label><br>
                                <label :class="[activeName == 'order' ? 'order_col_label' : '']">退款总金额：{{formData.order_back_amount_payable_sum}}元</label> <br>
                            </el-col>
                        </el-row>
                    </el-card>
                </el-col>
            </el-row>

            <!--主要内容-->
            <div class="table memberCard">
                <div v-if="activeName == 'total'" style="position:absolute;right:0;z-index: 1;height:39px;line-height:39px;margin-right:10px">
                    <el-radio-group v-model="dateRadio" style="margin-right:10px">
                        <el-radio label="month">月</el-radio>
                        <el-radio label="year">年</el-radio>
                    </el-radio-group>

                    <el-date-picker v-show="dateRadio == 'year'" v-model="inputyear" size="mini" type="year" value-format="yyyy" format="yyyy年" placeholder="选择年" @change="getList()">
                    </el-date-picker>
                    <el-date-picker v-show="dateRadio == 'month'" v-model="inputmonth" size="mini" type="month" value-format="yyyy-MM-dd" format="yyyy年MM月" placeholder="选择月" @change="getList()">
                    </el-date-picker>
                </div>
                <el-tabs v-model="activeName" type="border-card" @tab-click="handleTab">
                    <el-tab-pane label="个人统计" name="total">
                        <div v-if="activeName == 'total'" style="height:100%">
                            <el-row :gutter="20" type="flex" style="height:calc(100% - 20px);overflow:auto">
                                <el-col :span="12">
                                    <el-card shadow="never" class="chartsBox">
                                        <div class="order_title">单用户月消费统计</div>
                                        <div ref="zchart" id="zchart" :style="{width: '100%', height: '88%'}"></div>
                                    </el-card>
                                </el-col>

                                <el-col :span="12" style="height:calc(100%);overflow:auto">
                                    <el-card shadow="never" class="chartsBox1">
                                        <div class="order_title">消费者品类占比</div>
                                        <div ref="piechart" id="piechart" :style="{width: '100%', height: '86%'}"></div>
                                    </el-card>
                                    <el-card shadow="never" class="chartsBox2">
                                        <div class="order_title">单用户各品类月消费统计</div>
                                        <div ref="linechart" id="linechart" :style="{width: '100%', height: '82%'}"></div>
                                    </el-card>

                                </el-col>
                            </el-row>
                        </div>
                    </el-tab-pane>
                    <el-tab-pane label="订单管理" name="order">
                        <div v-if="activeName == 'order'" style="height:calc(100% - 65px);overflow:auto">
                            <div class="selectionBar" style="margin-top:0;">
                                <div class="flex flex-ai-c flex-wrap" style="padding:0 8px;">
                                    <label>场景：</label>
                                    <el-select v-model="select.order_method" placeholder="下单方式" clearable style="width:110px" @change="curr=1;getDetList()">
                                        <el-option v-for="item in selectData['下单方式']" :label="item.name" :value="item.id" :key="item.id"></el-option>
                                    </el-select>
                                    <el-select v-model="select.send_type" placeholder="配送方式" clearable style="width:110px" @change="curr=1;getOrderList()">
                                        <el-option v-for="item in selectData['配送方式']" :label="item.name" :value="item.id" :key="item.id"></el-option>
                                    </el-select>
                                    <el-select v-model="select.status" placeholder="订单状态" clearable style="width:110px" @change="curr=1;getDetList()">
                                        <el-option v-for="(item,index) in selectData['订单状态']" :label="item" :value="index" :key="index"></el-option>
                                    </el-select>
                                    <el-select v-model="select.type" placeholder="订单类型" clearable style="width:110px" @change="curr=1;getDetList()">
                                        <el-option v-for="item in selectData['订单类型']" :label="item.name" :value="item.id" :key="item.id"></el-option>
                                    </el-select>
                                    <el-select v-model="select.is_overtime" placeholder="订单是否超时" style="width:130px" clearable @change="curr=1;getDetList()">
                                        <el-option v-for="item in selectData['发票']" :label="item.name" :value="item.id" :key="item.id"></el-option>
                                    </el-select>
                                    <el-select v-model="select.is_invoice" placeholder="是否带发票" clearable style="width:120px" @change="curr=1;getDetList()">
                                        <el-option v-for="item in selectData['发票']" :label="item.name" :value="item.id" :key="item.id"></el-option>
                                    </el-select>
                                    <el-date-picker v-model="select.create_time" class="selectTimeWidth" size="small" type="daterange" range-separator="至" start-placeholder="创建时间" end-placeholder="筛选" value-format="yyyy-MM-dd" @change="curr=1;getDetList()">
                                    </el-date-picker>
                                </div>
                            </div>
                            <div class="table changeorderTab" style="height:calc( 100% - 76px );overflow:auto;">
                                <el-table :data="list" ref="barparent" stripe border height="calc( 100% - 20px )">
                                    <el-table-column align="center" label="下单方式">
                                        <template slot-scope="scope">
                                            {{scope.row.order_method == 2 ? '线上' : '线下'}}
                                            <div class="item" :style="{width:tableWidth}">
                                                订单编号：{{scope.row.order_sn}}
                                                <span style="color:#7B84FF;margin-left:40px">
                                                    订单状态：( {{scope.row.status_name}}
                                                    <span style="color:#F56C6C" v-if="scope.row.is_overtime == 1"><span style="color:#909399"> — </span>配送超时</span> )
                                                </span>
                                                <span style="margin-left:40px">创建时间：{{scope.row.create_time}}</span>
                                            </div>
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="商品信息" class="storeInfo" width="300" show-overflow-tooltip>
                                        <template slot-scope="scope">
                                            <div v-for="g in scope.row.orderDetails" :key="'goods_'+g.id" class="mesSty">
                                                <el-image class="pic" :src="domain + g.product_pic" fit="cover" :preview-src-list="[domain + g.product_pic]"></el-image>
                                                <div style="width:calc(100% - 105px);">
                                                    <div class="goodsName" style="overflow: hidden;text-overflow: ellipsis;white-space: nowrap;">{{g.product_name}}</div>
                                                    <p><span class="att">规格：</span>{{g.attr_name?g.attr_name : '暂无'}}</p>
                                                    <p><span class="att">单价：</span>{{g.price}}元</p>
                                                    <p><span class="att">数量：</span>{{g.number}}</p>
                                                    <p><span class="att">小计：</span>{{g.price * g.number}}元</p>
                                                </div>
                                            </div>
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="postage" align="left" label="配送费" show-overflow-tooltip>
                                    </el-table-column>
                                    <el-table-column prop="discount_amount" align="left" width="100" label="优惠券抵扣" show-overflow-tooltip>
                                    </el-table-column>
                                    <el-table-column prop="integral_amount" align="left" label="积分抵扣" show-overflow-tooltip>
                                    </el-table-column>
                                    <el-table-column prop="balance_amount" align="left" label="余额抵扣" show-overflow-tooltip>
                                    </el-table-column>
                                    <el-table-column prop="recharge_amount" align="left" width="100" label="会员卡抵扣" show-overflow-tooltip>
                                    </el-table-column>
                                    <el-table-column prop="address" align="left" label="商品类型" show-overflow-tooltip>
                                        <template slot-scope="scope">
                                            {{scope.row.type==1?'秒杀':scope.row.type==2?'拼团':'普通商品'}}
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="amount_payable" align="left" label="应付" show-overflow-tooltip>
                                    </el-table-column>
                                    <el-table-column prop="amount" align="left" label="实付" show-overflow-tooltip>
                                    </el-table-column>
                                    <el-table-column prop="address" align="left" width="100" label="是否带发票" show-overflow-tooltip>
                                        <template slot-scope="scope">
                                            {{scope.row.is_invoice==1?'是':'否'}}
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="address" align="left" width="210" label="收货信息" show-overflow-tooltip>
                                        <template slot-scope="scope">
                                            <div class="flex regoods">
                                                <label>会员卡号：</label>
                                                <div>{{scope.row.user && scope.row.user.card  ? scope.row.user.card : '暂无'}}</div>
                                            </div>
                                            <div class="flex regoods">
                                                <label>姓名：</label>
                                                <div>{{scope.row.name ? scope.row.name : '暂无'}}</div>
                                            </div>
                                            <div class="flex regoods">
                                                <label>电话：</label>
                                                <div>{{scope.row.mobile ? scope.row.mobile : '暂无'}}</div>
                                            </div>
                                            <div class="flex regoods">
                                                <label>地址：</label>
                                                <div style="overflow: hidden;text-overflow: ellipsis;white-space: nowrap;">{{scope.row.address ? scope.row.address : '暂无'}}</div>
                                            </div>
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="address" width="150" align="left" label="配送人员信息" show-overflow-tooltip>
                                        <template slot-scope="scope">
                                            <div v-if="scope.row.send_type  == 2">
                                                到店自取
                                            </div>
                                            <div v-else>
                                                <p>姓名：{{scope.row.delivery_name ? scope.row.delivery_name : '暂无'}}</p>
                                                <p>电话：{{scope.row.delivery_mobile ? scope.row.delivery_mobile : '暂无'}}</p>
                                            </div>
                                        </template>
                                    </el-table-column>
                                    <!-- <el-table-column fixed="right" label="操作" align="center" width="130">
                                        <template slot-scope="scope">
                                            <el-button type="text" :disabled="scope.row.order_method == 2 && (scope.row.status == 4 || scope.row.status == 6)  ?  false : true" @click="refund(scope.row.id)">退款</el-button>
                                            <el-button type="text" :disabled="scope.row.send_type  == 2 && scope.row.status == 2 ?  false : true" @click="orderOver(scope.row.id)">订单完成</el-button>
                                        </template>
                                    </el-table-column> -->
                                </el-table>

                                <!-- 分页 -->
                                <!-- <div class="paging">
                                    <el-pagination @current-change="currentChange" @size-change="sizeChange" :current-page="curr" :page-size="row" background layout="total, sizes, prev, pager, next, jumper" :total="count"></el-pagination>
                                </div> -->
                            </div>
                        </div>
                        <!-- 分页 -->
                        <div class="paging">
                            <el-pagination @current-change="currentChange" @size-change="sizeChange" :current-page="curr" :page-size="row" background layout="total, sizes, prev, pager, next, jumper" :total="count"></el-pagination>
                        </div>
                    </el-tab-pane>

                    <!--充值管理-->
                    <el-tab-pane label="充值管理" name="recharge">
                        <div class="el-tab-pane-box">
                            <div class="selectionBar">
                                <div class="flex flex-ai-c flex-wrap">
                                    <label>场景：</label>
                                    <el-date-picker v-model="select.create_time" class="selectTimeWidth" size="small" type="daterange" range-separator="至" start-placeholder="充值时间" end-placeholder="时间筛选" value-format="yyyy-MM-dd" @change="curr=1;getDetList()">
                                    </el-date-picker>
                                </div>
                            </div>
                            <el-table :data="list" stripe :header-cell-style="{background:'#fafdff',color:'#606266',fontWeight:'normal'}" height="calc( 100% - 85px )">
                                <el-table-column prop="num" label="充值金额" show-overflow-tooltip></el-table-column>
                                <el-table-column prop="pay_time" label="充值时间" show-overflow-tooltip></el-table-column>
                            </el-table>
                            <!-- 分页 -->
                            <div class="paging">
                                <el-pagination @current-change="currentChange" @size-change="sizeChange" :current-page="curr" :page-size="row" background layout="total, sizes, prev, pager, next, jumper" :total="count"></el-pagination>
                            </div>
                        </div>
                    </el-tab-pane>

                    <!--推广管理-->
                    <el-tab-pane label="推广管理" name="share">
                        <div class="el-tab-pane-box">
                            <div class="selectionBar">
                                <div class="flex flex-ai-c flex-jc-sb flex-wrap">
                                    <div>
                                        <label>场景：</label>
                                        <el-date-picker v-model="select.create_time" class="selectTimeWidth" size="small" type="daterange" range-separator="至" start-placeholder="创建时间" end-placeholder="时间筛选" value-format="yyyy-MM-dd" @change="curr=1;getDetList()">
                                        </el-date-picker>
                                    </div>
                                    <!-- <div>
                                        <el-button type="success" size="mini">订单总金额：{{order_amount_sum}}元</el-button>
                                    </div> -->
                                </div>
                            </div>
                            <el-table :data="list" stripe :header-cell-style="{background:'#fafdff',color:'#606266',fontWeight:'normal'}" @sort-change='getList()' height="calc( 100% - 85px )">
                                <el-table-column prop="custom.nickname" label="推广（昵称）" show-overflow-tooltip></el-table-column>
                                <el-table-column prop="username" label="订单详情" width="500" show-overflow-tooltip>
                                    <template slot-scope="scope">
                                        <el-form label-position="left" inline>
                                            <el-form-item label="商品规格：">
                                                <p v-for="(item,index) in scope.row.orderDetails" :key="index">{{item.attr_name}}</p>
                                            </el-form-item>
                                            <el-form-item label="数量：" style="margin-left:30px">
                                                <p v-for="(item,index) in scope.row.orderDetails" :key="index">{{item.number}}</p>
                                            </el-form-item>
                                            <el-form-item label="价格：" style="margin-left:30px">
                                                <p v-for="(item,index) in scope.row.orderDetails" :key="index">{{item.price?item.price : 0}}元</p>
                                            </el-form-item>
                                        </el-form>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="num" label="订单分红" show-overflow-tooltip></el-table-column>
                                <el-table-column prop="status_name" label="分红状态" show-overflow-tooltip></el-table-column>
                                <el-table-column prop="create_time" label="创建时间" show-overflow-tooltip></el-table-column>
                            </el-table>
                            <!-- 分页 -->
                            <div class="paging">
                                <el-pagination @current-change="currentChange" @size-change="sizeChange" :current-page="curr" :page-size="row" background layout="total, sizes, prev, pager, next, jumper" :total="count"></el-pagination>
                            </div>
                        </div>
                    </el-tab-pane>

                    <!--积分管理-->
                    <el-tab-pane label="积分管理" name="integral">
                        <div class="el-tab-pane-box">
                            <div class="selectionBar">
                                <div class="flex flex-ai-c flex-wrap">
                                    <label>场景：</label>
                                    <el-date-picker v-model="select.create_time" class="selectTimeWidth" size="small" type="daterange" range-separator="至" start-placeholder="创建时间" end-placeholder="时间筛选" value-format="yyyy-MM-dd" @change="curr=1;getDetList()">
                                    </el-date-picker>
                                </div>
                            </div>
                            <el-table :data="list" stripe :header-cell-style="{background:'#fafdff',color:'#606266',fontWeight:'normal'}" @sort-change='getDetList()' height="calc( 100% - 85px )">
                                <el-table-column prop="order_sn" width="300" label="积分订单编号" show-overflow-tooltip></el-table-column>
                                <el-table-column prop="integral_order" label="购物返积分" show-overflow-tooltip></el-table-column>
                                <el-table-column prop="Integral_evaluate_good" label="好评返积分" show-overflow-tooltip></el-table-column>
                                <el-table-column prop="Integral_evaluate_middle" label="中评返积分" show-overflow-tooltip></el-table-column>
                                <el-table-column prop="Integral_evaluate_bad" label="差评返积分" show-overflow-tooltip></el-table-column>
                                <el-table-column prop="integral_order_pay" label="已使用积分付款" show-overflow-tooltip></el-table-column>
                                <el-table-column prop="create_time" label="创建时间" show-overflow-tooltip></el-table-column>
                            </el-table>
                            <!-- 分页 -->
                            <div class="paging">
                                <el-pagination @current-change="currentChange" @size-change="sizeChange" :current-page="curr" :page-size="row" background layout="total, sizes, prev, pager, next, jumper" :total="count"></el-pagination>
                            </div>
                        </div>
                    </el-tab-pane>

                    <el-tab-pane label="优惠券管理" name="discount">
                        <div class="el-tab-pane-box">
                            <div class="selectionBar">
                                <div class="flex flex-ai-c flex-wrap">
                                    <label>场景：</label>
                                    <el-date-picker v-model="select.create_time" class="selectTimeWidth" size="small" type="daterange" range-separator="至" start-placeholder="创建时间" end-placeholder="时间筛选" value-format="yyyy-MM-dd" @change="curr=1;getDetList()">
                                    </el-date-picker>
                                </div>
                            </div>
                            <el-table :data="list" stripe :header-cell-style="{background:'#fafdff',color:'#606266',fontWeight:'normal'}" height="calc( 100% - 85px )">
                                <el-table-column prop="discount_name" label="优惠券名称" show-overflow-tooltip></el-table-column>
                                <el-table-column label="优惠券类型" show-overflow-tooltip>
                                    <template slot-scope="scope">
                                        <span>{{scope.row.type == 1 ? '新人优惠' : '会员购物'}}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="full" label="满够金额" show-overflow-tooltip></el-table-column>
                                <el-table-column prop="reduce" label="优惠金额" show-overflow-tooltip></el-table-column>
                                <el-table-column label="使用时间" show-overflow-tooltip>
                                    <template slot-scope="scope">
                                        <span>{{scope.row.use_start_time }} 至 {{scope.row.use_end_time}}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="status_name" label="使用状态" show-overflow-tooltip></el-table-column>
                                <el-table-column prop="use_start_time" label="领取时间" show-overflow-tooltip></el-table-column>
                            </el-table>
                            <!-- 分页 -->
                            <div class="paging">
                                <el-pagination @current-change="currentChange" @size-change="sizeChange" :current-page="curr" :page-size="row" background layout="total, sizes, prev, pager, next, jumper" :total="count"></el-pagination>
                            </div>
                        </div>
                    </el-tab-pane>

                </el-tabs>

            </div>
        </div>
    </div>
</template>
<script>
import { memberReadApi, memberReadListApi, personlChartApi } from '@/api/member.js';
import { orderStatusListApi } from '@/api/order.js';
var echarts = require('echarts');
export default {
    data() {
        return {
            formData: {},
            list: [],
            select: {},
            chartsData: {},

            selectData: {
                '下单方式': [{
                    id: 2,
                    name: '线上'
                }, {
                    id: 1,
                    name: '线下'
                }],
                '配送方式': [{
                    id: 1,
                    name: '门店配送'
                }, {
                    id: 2,
                    name: '到店自取'
                }],
                '订单类型': [{
                    id: 0,
                    name: '普通订单'
                }, {
                    id: 1,
                    name: '秒杀'
                }, {
                    id: 2,
                    name: '拼团'
                }],
                '发票': [{
                    id: 1,
                    name: '是'
                }, {
                    id: 2,
                    name: '否'
                }]
            },

            curr: 1, //页码
            row: 10, //每页条数
            pages: 1, //总页数
            count: 0, //总条数
            activeName: 'total',

            dateRadio: 'month',

            inputyear: '',
            inputmonth: '',
            type: '',
            tableWidth:0,

            imgStatus: false
            // order_amount_sum:0,
        }
    },
    mounted() {
        this.init();
    },
    watch: {
        isCollapse() {
            let _this = this;
            setTimeout(() => {
                _this.initCharts();
            }, 300)
        },
        'dateRadio': function () {
            var myDate = new Date();
            switch (this.dateRadio) {
                case 'year':
                    this.inputyear = String(myDate.getFullYear());
                    break;
                case 'month':
                    this.inputmonth = (new Date(myDate.getFullYear(), myDate.getMonth(), 1))
                    break;
                default:
                    break;
            }
            this.getChart();
        }
    },
    methods: {
        init: function () {
            let _this = this;
            //获取会员详情
            this.getDet();
            //获取tab列表
            // this.getDetList();
            //获取订单状态
            this.getStatusList();

            this.$nextTick(() => {
                if (_this.dateRadio == 'month') {
                    var myDate2 = new Date();

                    // this.inputyear = String(myDate2.getFullYear());
                    _this.inputmonth = _this.dataFormat(new Date(myDate2.getFullYear(), myDate2.getMonth(), 1));
                    _this.getChart();
                }
                window.onresize = () => { //  根据窗口大小调整曲线大小
                    _this.initCharts();
                }
                // _this.getChart();
                // _this.drawBar();
                // _this.drawLine();
                // _this.drawPie();
            })

        },
        initCharts: function () {
            let barChart = echarts.init(document.getElementById('zchart'));
            let lineChart = echarts.init(document.getElementById('linechart'));
            let pieChart = echarts.init(document.getElementById('piechart'));
            barChart.resize();
            lineChart.resize();
            pieChart.resize();
        },
        //会员详情
        getDet: function () {
            memberReadApi({
                id: this.$route.query.id
            }).then(response => {
                this.formData = response;
                this.imgStatus = response.headimgurl ? false : true
            })
        },

        //会员信息列表
        getDetList: function () {
            memberReadListApi({
                curr: this.curr,
                row: this.row,
                list_type: this.activeName,
                user_id: this.$route.query.id,
                ...this.select
            }).then(response => {
                this.list = response.list;
                this.curr = response.curr;
                this.pages = response.pages;
                this.count = response.count;
            })
        },
        getChart: function () {
            if (this.dateRadio == 'year') {
                this.selectDate = this.inputyear;
                this.type = 3;
            } else if (this.dateRadio == 'month') {
                this.selectDate = this.inputmonth;
                this.type = 2;
            }

            personlChartApi({
                user_id: this.$route.query.id,
                type: this.type,
                date: this.selectDate
            }).then(response => {
                this.chartsData = response;
                this.drawBar();
                this.drawPie();
                this.drawLine();
            })
        },
        //饼图
        drawPie: function () {
            var myChart = echarts.init(document.getElementById('piechart'));
            myChart.setOption({
                tooltip: {
                    trigger: 'item'
                },
                legend: {
					type: 'scroll',
					orient: 'vertical',
					right: 10,
					top: 20,
					bottom: 20,
				},
                series: [{
                    name: '消费者品类占比',
                    type: 'pie',
                    radius: '50%',
                    data: this.chartsData.pieChart.data,
                    emphasis: {
                        itemStyle: {
                            shadowBlur: 10,
                            shadowOffsetX: 0,
                            shadowColor: 'rgba(0, 0, 0, 0.5)'
                        }
                    }
                }]
            })
        },
        // 折线图
        drawLine: function () {
            let _this = this;
            var myChart = echarts.init(document.getElementById('linechart'));
            myChart.setOption({
                tooltip: {
                    trigger: 'axis',
                    axisPointer: { // 坐标轴指示器，坐标轴触发有效
                        label: {
                            show: true
                        }
                    },

                },
                grid: { //位置
                    // top:'%',
                    left: '3%',
                    right: '3%',
                    bottom: '3%',
                    containLabel: true
                },
                xAxis: {
                    type: 'category',

                    data: this.chartsData.line.xAxis
                },
                yAxis: {
                    type: 'value',
                    splitLine: {
                        show: true,
                        lineStyle: {
                            type: 'dashed'
                        }
                    }
                },
                series: _this.lineSeries()
            });
        },
        lineSeries: function () {
            var series = [];
            for (var item of this.chartsData.line.yAxis) {
                series.push({
                    name: item.name,
                    type: 'line',
                    data: item.value,
                    emphasis: {
                        itemStyle: {
                            shadowBlur: 10,
                            shadowOffsetX: 0,
                            shadowColor: 'rgba(0, 0, 0, 0.5)'
                        }
                    },
                    smooth: true,
                    itemStyle: {
                        normal: {
                            color: item.color,
                            label: {
                                show: true,
                                position: 'top',
                            }
                        }
                    },
                })
            }
            return series;
        },

        // 柱形图
        drawBar: function () {
            var myChart = echarts.init(document.getElementById('zchart'));
            var option = {
                tooltip: {
                    trigger: 'axis',
                },
                grid: { //位置
                    left: '3%',
                    right: '3%',
                    bottom: '0%',
                    top: '10%',
                    containLabel: true
                },
                xAxis: [{
                    type: 'category',
                    data: this.chartsData.barChart.xAxis,
                    axisPointer: {
                        type: 'shadow'
                    }
                }],
                yAxis: {
                    type: 'value',
                    splitLine: {
                        show: true,
                        lineStyle: {
                            type: 'dashed'
                        }
                    }
                },
                series: [{
                    name: this.chartsData.barChart.name,
                    type: 'bar',
                    data: this.chartsData.barChart.yAxis,
                    itemStyle: {
                        normal: {
                            color: '#7B84FF',//设置柱子颜色
                            label: {
                                show: true,//柱子上显示值
                                position: 'top',//值在柱子上方显示
                                textStyle: {
                                    color: '#7B84FF'//值得颜色
                                }
                            }
                        }
                    },
                }]
            };
            myChart.setOption(option);
        },

        //获取订单状态
        getStatusList: function () {
            orderStatusListApi().then(response => {
                this.selectData['订单状态'] = response;
            })
        },
        //tab切换
        handleTab: function () {
            let _this = this;
            this.select = {};
            this.curr = 1;
            this.row = 10;
            this.list = [];
            if (this.activeName == 'total') {
                this.$nextTick(() => {
                    window.onresize = () => { //  根据窗口大小调整曲线大小
                        _this.initCharts();
                    }
                    _this.drawBar();
                    _this.drawLine();
                    _this.drawPie();
                })
                return;
            }
            if(this.activeName == 'order'){
                this.$nextTick(() => {
                    _this.tableWidth = _this.$refs.barparent.bodyWidth; 
                })  
            }
            this.getDetList();
        },
        //返回
        goBack: function () {
            this.$router.go(-1);
        },
        //时间转换
        dataFormat: function (time) {
            return `${time.getFullYear()}-${time.getMonth() + 1 >= 10 ? (time.getMonth() + 1) : '0' + (time.getMonth() + 1)}-${time.getDate() >= 10 ? time.getDate() : '0' + time.getDate()}`;
        },
        //翻页
        currentChange: function (curr) {
            this.curr = curr;
            this.getDetList();
        },
        //改变每页条数
        sizeChange: function (row) {
            this.row = row;
            this.getDetList();
        }
    },
}
</script>
<style scoped>

.changeorderTab .el-table--enable-row-transition >>> .el-table__body td {
    padding-top: 52px;
}
.changeorderTab :v-deep .el-table tr {
    position: relative;
    width: 100%;
}
.changeorderTab .item {
    background: #f2f3ff;
    padding: 10% 0;
    position: absolute;
    top: 0;
    left: 0px !important;
    z-index: 4;
    text-align: left;
    padding-left: 10px;
    box-sizing: border-box;
}
.changeorderTab .mesSty .pic {
    width: 80px;
    height: 80px;
    margin: 0 10px;
    object-fit: cover;
    border-radius: 3px;
    display: block;
}
.changeorderTab .mesSty {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #ebeef5;
}

.changeorderTab .mesSty:last-child {
    border: none;
}
</style>